/**
 * @file SignupController
 * @description
 * Load into your application like `myApp.controller('CategorySelectionController', require('CategorySelectionController.js'))`
 */
module.exports = ["$scope", "DataService", "ChannelRepository", "AppConstants", function($scope, DataService, ChannelRepository, AppConstants) {
    const _channel    = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME);
    var _controller   = this,
          _categories = [],
          _defaultCategory;

    _controller.getCategories = ((promise) => () => {
        if(!promise) {
            promise = DataService.getAllCategories()
                                 .then((categories) => {
                                     Array.prototype.push.apply(_categories, categories.filter((c) => !!+c.is_postable));
                                 })
                                 .then(() => {
                                     _defaultCategory = _categories.filter((c) => c.is_default)[0];
                                     setDefaultCategory();
                                 });
        }

        return _categories;
    })(false);

    function setDefaultCategory() {
        if(_defaultCategory) $scope.signup.data.categories = [_defaultCategory.name];
    }

    (function init() {
        _channel.subscribe(AppConstants.CHANNEL_SUBMISSION.ACTION_RESET, setDefaultCategory);

        $scope.$on('$destroy', () => {
            _channel.unsubscribe(AppConstants.CHANNEL_SUBMISSION.ACTION_RESET, setDefaultCategory);
        });
    }());
}];
