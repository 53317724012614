/**
 * @file CommitmentController
 * @description
 * Load into your application like `myApp.controller('CommitmentController', require('CommitmentController.js'))`
 */
module.exports = ["$scope", "$filter", "AppConstants", "CTServiceAPIConfig", "CTHashService", "POPUP_HASH_KEY", function($scope, $filter, AppConstants, CTServiceAPIConfig, CTHashService, POPUP_HASH_KEY) {
    var _controller = this,
        _item       = () => ($scope.item || {});

    /**
     * Check if an image is available
     * @return {boolean}
     */
    _controller.hasImage = () => !!_item().image;

    /**
     * Check if a video is available
     * @return {boolean}
     */
    _controller.hasVideo = () => !!_item().video_url;

    /**
     * Check if a statement is available
     * @return {boolean}
     */
    _controller.hasTitle = () => !!_item().header;

    /**
     * Return a trusted HTML String for angular
     * @return {*}
     */
    _controller.getHTMLSafeText = () => $filter('parseLinks')(_item().text || '');

    /**
     * Return a teaser text
     * @return {string}
     */
    _controller.getTeaserText = () => {
        var text   = (_item().text || ''),
            teaser = (/.{0,120}\w+/.exec(text) || [''])[0];

        return teaser + (text == teaser ? '' : '…');
    };

    /**
     * Check if any category allows votes
     */
    _controller.isVoteable    = () => (_item().categories || []).some((category) => category.is_voteable);

    /**
     * Check if any category allows comments
     */
    _controller.isCommentable = () => (_item().categories || []).some((category) => category.is_commentable);

    /**
     * Check if any category allows to show comments
     */
    _controller.showComments  = () => (_item().categories || []).some((category) => category.show_comments);

    _controller.hasAnswer  = () => ((_item().categories || []).some((category) => category.show_comments) && (_item().comment_count > 0));

    /**
     * Return the popup hash
     */
    _controller.getPopupLink = () => CTHashService.getHash({[POPUP_HASH_KEY]: _item().id});

    _controller.getFirstComment = () => ((_item() || {}).comments || [])[0];

    /**
     * Generate a share url for this commitment
     * @return {string}
     */
    _controller.getShareURL = () => {
        return `${location.protocol}//${location.hostname}${location.pathname}#${POPUP_HASH_KEY}=${_item().id}`;
        // removed this, because it seems like this is not working anymore -> let's just use the normal url
        // return redirect_url; // `${CTServiceAPIConfig.HOST}/api/v1/pages/${AppConstants.PAGE_ID}/user_generated_contents/${_item().id}/share?redirect_url=${redirect_url}`;
    };

    (function init() {

    }());
}];
