/**
 * @file SearchController
 * @description
 * Load into your application like `myApp.controller('SearchController', require('SearchController.js'))`
 */
module.exports = ["$scope", "ChannelRepository", "AppConstants", "CTHashService", "CTHash", function($scope, ChannelRepository, AppConstants, CTHashService, CTHash) {
    var _controller = this,
        _channel = ChannelRepository.get(AppConstants.CHANNEL_FILTER.NAME),
        _sendingTimeout;

    /**
     * Define the filter model
     * @type {{q: string}}
     */
    _controller.filter = {
        q: ''
    };

    /**
     * Send the filter after a timeout or immediate if requested
     *
     * @param {boolean} sendImmediate
     */
    _controller.sendNewFilter = (sendImmediate) => {
        window.clearTimeout(_sendingTimeout);

        _sendingTimeout = setTimeout(_sendFilter, sendImmediate ? 0 : 1000);
    };

    /**
     * Reset the current filter `q`
     */
    _controller.resetFilter = () => {
        _controller.filter.q = '';
        _controller.sendNewFilter(true);
    };

    /**
     * Notify the channel about the filter
     * This function should not be called outside `_controller.sendNewFilter`
     * @private
     */
    function _sendFilter() {
        var filter = angular.copy(_controller.filter);

        if(!filter.q) filter.q = null;

        CTHashService.set('suche', _controller.filter.q);
        _channel.notify(AppConstants.CHANNEL_FILTER.ACTION_FILTER, filter);
    }


    /**
     * When the hash changes we check if the filter needs to be updated
     * @private
     */
    function _applyFilterFromHash() {
        var filter = (CTHashService.get('suche') || '');

        if(filter !== _controller.filter.q) {
            _controller.filter.q = filter;
            _controller.sendNewFilter(true);
        }
    }


    (function init() {
        CTHash.change(_applyFilterFromHash);
    }());
}];
