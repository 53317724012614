/**
 * @file VideoSelectionController
 * @description
 * Load into your application like `myApp.controller('VideoSelectionController', require('VideoSelectionController.js'))`
 */
module.exports = ["$scope", "debounce", "$http", "$q", function($scope, debounce, $http, $q) {
    var _controller  = this,
        _showInput   = false,
        _importError = false;

    _controller.video_url = '';


    _controller.showInput = (state) => {
        if(state === false || state === true) _showInput = state;

        return _showInput && !_controller.hasVideo();
    };

    _controller.hasVideo = () => !!$scope.signup.data.video_url;

    _controller.showInputError = () => (_importError === _controller.video_url);


    function parseVideoURL() {
        if(_controller.video_url == '') return;

        $scope.signup.hasNetworkActivity(true);

        _importError = false;

        const
            setVideoData  = (data) => {
                $scope.signup.mediaDrop.setMedia(data.image, 'VIDEO');

                $scope.signup.data.media_type = 'VIDEO';
                $scope.signup.data.image  = data.image;
                $scope.signup.data.video_url  = data.video_url;

                $scope.signup.hasNetworkActivity(false);
            },
            failedLoading = () => {
                _importError = _controller.video_url;
                $scope.signup.hasNetworkActivity(false);
            };

        $q.reject()
          .then(null, checkForYoutube)
          .then(null, checkForVimeo)
          .then(null, checkForInstagram)
          .then(setVideoData, failedLoading);

    }

    _controller.parseVideoURL = debounce(parseVideoURL, 500);


    /**
     * Check the given URL for a Youtube Video
     * @return {promise}
     */
    function checkForYoutube() {
        var url = _controller.video_url;
        return $q((resolve, reject) => {
            if(url.indexOf('youtube.com') >= 0 || url.indexOf('youtu.be') >= 0) {

                //Extract video ID from url
                var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
                    match  = url.match(regExp);

                if(!match || match.length < 8) return reject();

                var VID = match[7];

                //Set the selected media
                return resolve({
                    image: `https://i3.ytimg.com/vi/${VID}/0.jpg`,
                    video_url: `https://youtu.be/${VID}`
                });
            }

            reject();
        });
    }

    /**
     * Check the given URL for a Vimeo Video
     * @return {promise}
     */
    function checkForVimeo() {
        var url = _controller.video_url;

        return $q((resolve, reject) => {
            if(url.indexOf('vimeo.com') >= 0) {

                //Extract video ID from url
                var regExp = /vimeo.com\/.*?(\d+)\/?/,
                    match  = url.match(regExp);

                if(!match || match.length < 2) return reject();

                var VID = match[1];

                $http.get(`https://vimeo.com/api/v2/video/${VID}.json`)
                     .then((response) => resolve({
                         image: response.data['0'].thumbnail_large,
                         video_url: `https://vimeo.com/${VID}`
                     }), reject);
            }
            else {
                reject();
            }
        });
    }

    /**
     * Check the given URL for a Instagram Video
     * @return {promise}
     */
    function checkForInstagram() {
        var url = _controller.video_url;

        return $q((resolve, reject) => {
            if(url.indexOf('instagram.com') >= 0) {
                //Extract URL from EmbedInput
                var regExp = /https?:\/\/instagr(\.am|am\.com)\/p\/([^\/\s]+)/,
                    match  = url.match(regExp);

                if(match.length < 3) return reject();

                var VID = match[2];

                $http.get(`https://api.instagram.com/oembed/?url=${match[0]}&callback=?`)
                     .then((response) => resolve({
                         image: response.data.thumbnail_url,
                         video_url: `https://instagram.com/p/${VID}/embed`
                     }), reject);
            }
            else {
                reject();
            }
        });
    }

    (function init() {

    }());
}];
