module.exports={
    "PAGE_SIZE": 20,
    "USE_CACHE": true,
    "CHANNEL_FILTER": {
        "NAME": "filter",
        "ACTION_FILTER": "filter"
    },
    "CHANNEL_SOCIALMEDIA": {
        "NAME": "socialmedia",
        "ACTION_FB_CONNECT": "connected-with-facebook"
    },
    "CHANNEL_SUBMISSION": {
        "NAME": "submission",
        "ACTION_OPEN_SIGNUP": "open_signup",
        "ACTION_CLOSE_SIGNUP": "close_signup",
        "ACTION_SUBMITTED": "submitted",
        "ACTION_RESET": "reset",
        "ACTION_VOTE_RESET": "vote_reset",
        "ACTION_VOTE_SUBMITTED": "vote_submitted",
        "ACTION_COMMENT_RESET": "comment_reset",
        "ACTION_COMMENT_SUBMITTED": "comment_submitted",
        "ACTION_STEP": "submitted_step"
    },
    "CHANNEL_WALL": {
        "NAME": "wall",
        "ACTION_PAGELOAD": "pageload"
    },
    "CHANNEL_SHARE": {
        "NAME": "share",
        "ACTION_SHARE": "share"
    },
    "CHANNEL_POPUP": {
        "NAME": "popup",
        "ACTION_OPEN": "open",
        "ACTION_CLOSE": "close"
    },
    "SHARE": {
        "TWITTER": {
            "TEXT": "",
            "HASHTAGS": "",
            "RELATED": ""
        }
    },

    "ANIMATION": {
        "EASINGS": {
            "DEFAULT": "[.76,-0.29,.45,1.2]"
        }
    }
}
