/**
 * @file PopupSupervisor
 * @description
 * Load into your application like `myApp.run(require('PopupSupervisor.js'))`
 */
module.exports = ["POPUP_HASH_KEY", "CTHash", "CTHashService", "ChannelRepository", "AppConstants", "$rootScope", "$compile", "$templateRequest", "$q", function(POPUP_HASH_KEY, CTHash, CTHashService, ChannelRepository, AppConstants, $rootScope, $compile, $templateRequest, $q) {
    var _currentKey    = false,
        _channel       = ChannelRepository.get(AppConstants.CHANNEL_POPUP.NAME),
        _popupTemplate,
        _popupInstance = {};

    function checkPopupKey() {
        var key = CTHashService.get(POPUP_HASH_KEY);

        if(key && key != _currentKey) {
            if(_popupInstance.element || _popupInstance.scope) {
                _destroyPopup().then(() => _createPopup(key));
            }
            else {
                _createPopup(key);
            }
        }
        else if(!key) {
            if(_popupInstance.element || _popupInstance.scope) {
                _destroyPopup();
            }
        }

        _currentKey = key;
    }

    /**
     * React on keyup event
     * @param event
     */
    function onKeyupHandler(event) {
        if(event.keyCode == 27) $rootScope.$applyAsync(() => CTHashService.del(POPUP_HASH_KEY));
    }


    function _createPopup(key) {
        _popupInstance.scope = $rootScope.$new();

        //Compile the element
        $compile(_popupTemplate)(_popupInstance.scope, (clone) => {

            _popupInstance.element = clone[0];

            //Add to the body
            document.body.appendChild(_popupInstance.element);

            setTimeout(() => {
                //Notify App
                $rootScope.$applyAsync(() => _channel.notify(AppConstants.CHANNEL_POPUP.ACTION_OPEN, key));
            }, 10);
        });
    }

    function _destroyPopup() {
        return _channel.notify(AppConstants.CHANNEL_POPUP.ACTION_CLOSE).then(() => $q((resolve) => {
            setTimeout(() => {
                if(_popupInstance.scope) _popupInstance.scope.$destroy();
                if(_popupInstance.element) _popupInstance.element.parentNode.removeChild(_popupInstance.element);

                _popupInstance = {};

                resolve();
            }, 10);
        }));
    }

    document.addEventListener('keyup', onKeyupHandler);

    setTimeout(() => CTHash.change(checkPopupKey), 1);

    /**
     * get popup template
     */
    $templateRequest('theme/popup').then((data) => {
        _popupTemplate = data;
    });
}];
