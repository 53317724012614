/**
 * @ngdoc service
 * @name CTChannelRepository
 *
 * @description
 * Creates and holds Channels based on module-type and module-identifier.
 */
const moduleName = 'cambuildr.service.channel';

angular.module(moduleName, []).service('ChannelRepository', ["$q", "$timeout", function($q, $timeout) {

    /**
     * @ngdoc service
     * @name CTChannel
     *
     * @description
     * This is the Channel service. You can subscribe on this channel an get your callback called everytime a publisher
     * notifies the channel with your subscribed action.
     *
     * {@link CTChannelRepository CTChannelRepository}.
     *
     * @param {string} name the name of the channel
     */
    function CTChannel(name) {
        console.groupCollapsed('CHANNEL-CREATE :: %o', name);
        console.trace();
        console.groupEnd('CHANNEL-CREATE :: %o', name);

        var _channel   = this,
            _name      = name || '',
            _callbacks = {};


        /**
         * @ngdoc function
         * @name CTChannel#subscribe
         * @methodOf CTChannel
         *
         * @description
         * subscribes a callback on a given Action
         *
         * @param {string} action Must be a value of CTChannelActionConstants
         * @param {function} callback the function to call if the channel gets notified
         */
        _channel.subscribe = function(action, callback) {
            console.debug('CHANNEL-SUBSCRIBE :: %o :: Action %o :: Callback %o', _name, action, callback);
            if(!_callbacks[action]) {
                _callbacks[action] = [];
            }
            if(_callbacks[action].indexOf(callback) === -1) {
                _callbacks[action].push(callback);
            }

            return _channel;
        };

        /**
         * @ngdoc function
         * @name CTChannel#unsubscribe
         * @methodOf CTChannel
         *
         * @description
         * unsubscribes a callback on a given Action
         *
         * @param {string} action Must be a value of CTChannelActionConstants
         * @param {function} callback the function to unsubscribe
         */
        _channel.unsubscribe = function(action, callback) {
            console.debug('CHANNEL-UNSUBSCRIBE :: %o :: Action %o :: Callback %o', _name, action, callback);
            if(_callbacks[action]) {
                var callbackIndex = _callbacks[action].indexOf(callback);
                if(callbackIndex != -1) {
                    _callbacks[action].splice(callbackIndex, 1);
                }
            }

            return _channel;
        };

        /**
         * @ngdoc function
         * @name CTChannel#notify
         * @methodOf CTChannel
         *
         * @description
         * notifies the channel for a given action.
         *
         * All parameters after action, will be applied to the subscribe-callback-functions.
         *
         * @param {string} action Must be a value of CTChannelActionConstants
         *
         * @returns {promise} A Promise that is resolved, when all callbacks are notified.
         */
        _channel.notify = function(action, ...notifyArgs) {
            console.debug('CHANNEL-NOTIFY :: ', _name, ' :: Action', action, ' :: Args ', notifyArgs);
            var deferred = [];

            if(_callbacks[action]) {
                deferred = deferred.concat(_callbacks[action].map((callback) => $q((resolve) => {
                    $timeout(() => {
                        callback.apply(undefined, notifyArgs);
                        resolve(true);
                    });
                })));
            }


            return $q.all(deferred);
        };

        /**
         * @ngdoc function
         * @name CTChannel#toString
         * @methodOf CTChannel
         *
         * @description
         * provides the Name of the Channel
         *
         * @returns {string|string} the name of the channel
         */
        _channel.toString = function() {
            return _name;
        };

        return _channel;
    }

    var _channels = {};


    /**
     * @ngdoc function
     * @name CTChannelRepository#get
     * @methodOf CTChannelRepository
     *
     * @description
     * Returnes a Channel created for your module.
     *
     * Although both parameters are mandatory, there is one special case.
     * If a Channel is created for people, only the module_type parameter is mandatory
     * (see example).
     *
     * @param {string} identifier
     * The type of the given module
     *
     * @return {CTChannel} The generated `CTChannel`.
     *
     * @example
     * <pre>
     *     //Creates a `CTChannel` for Stories
     *     var channel = CTChannelRepository.get("story", "5a515e28da4bf0d306c57e3d8a97c5ed");
     *
     *     //Creates a `CTChannel` for StoryComments for the story with id 23
     *     var channel = CTChannelRepository.get("story_comment", "5a515e28da4bf0d306c57e3d8a97c5ed", 23);
     *
     *     //Special usage for people
     *     var channel = CTChannelRepository.get("people");
     * </pre>
     */
    this.get = function(identifier) {

        if(!_channels[identifier]) {
            _channels[identifier] = new CTChannel(identifier);
        }

        return _channels[identifier];
    };
}]);

module.exports = moduleName;
