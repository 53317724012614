/**
 * @file SocialMediaSignupController
 * @description
 * Load into your application like `myApp.controller('SocialMediaSignupController', require('SocialMediaSignupController.js'))`
 */
module.exports = ["$scope", "$q", "ChannelRepository", "AppConstants", function($scope, $q, ChannelRepository, AppConstants) {
    var _controller = this,
        _channel    = ChannelRepository.get(AppConstants.CHANNEL_SOCIALMEDIA.NAME),

        _flag       = {
            fbAvailable: false
        };


    /**
     * Signup with facebook
     */
    _controller.facebook = (dataController, context, itemID = 'new') => {
        console.log('in here');
        dataController.hasNetworkActivity(true);

        //Create a promise while communicating with FB
        $q((resolve, reject) => {

            //Login to FB
            FB.login((response) => {

                if(response.authResponse) {

                    //Fetch user data
                    FB.api('/me', {fields: 'id,first_name,last_name,email'}, (response) => {
                        resolve(response);
                    });
                }
                else {
                    reject(response);
                }
            }, {scope: 'email'});
        })
        //When FB was connected set the data into the model
            .then((data) => {

                dataController.data.person.name       = data.first_name;
                dataController.data.person.surname    = data.last_name;
                dataController.data.person.email      = data.email;
                dataController.data.person.hasFBEmail = !!dataController.data.person.email;

                dataController.data.person.social_connectors = (dataController.data.person.social_connectors || []);
                dataController.data.person.social_connectors = [{
                    name      : 'facebook',
                    foreign_id: data.id
                }];

                //Notify the channel about the connection
                _channel.notify(AppConstants.CHANNEL_SOCIALMEDIA.ACTION_FB_CONNECT, data, context, itemID);

            })
            //Reset network activity
            .finally(() => dataController.hasNetworkActivity(false));

    };


    /**
     * Check if user is connected with facebook
     */
    _controller.usesFacebook = (dataController) => (dataController.data.person.social_connectors || []).some((connector) => connector.name === 'facebook');
    _controller.usesFacebookWithEmail = (dataController) => dataController.data.person.hasFBEmail && _controller.usesFacebook(dataController);

    _controller.facebookIsAvailable = () => _flag.fbAvailable;


    /**
     * Check if facebook is available
     */
    function checkForFB() {
        if(typeof FB !== 'undefined') {
            $scope.$applyAsync(() => (_flag.fbAvailable = true));
        }
        else {
            setTimeout(checkForFB, 200);
        }
    }


    (function init() {
        checkForFB();
    }());
}];
