/**
 * @file SearchController
 * @description
 * Load into your application like `myApp.controller('SearchController', require('SearchController.js'))`
 */
module.exports = ["$scope", "ChannelRepository", "AppConstants", "CTHashService", "CTHash", "DataService", function($scope, ChannelRepository, AppConstants, CTHashService, CTHash, DataService) {
    var _controller = this,
        _channel    = ChannelRepository.get(AppConstants.CHANNEL_FILTER.NAME),
        _sendingTimeout,
        _categories = [{
            id  : 0,
            name: 'Alle Kategorien' //TODO: use translations
        }],
        _categoriesPromise;

    /**
     * Define the filter model
     * @type {{q: string}}
     */
    _controller.filter = {
        category: 0
    };

    /**
     * Send the filter after a timeout or immediate if requested
     *
     * @param {boolean} sendImmediate
     */
    _controller.sendNewFilter = (sendImmediate) => {
        window.clearTimeout(_sendingTimeout);

        _sendingTimeout = setTimeout(_sendFilter, sendImmediate ? 0 : 1000);
    };

    /**
     * Reset the current filter `q`
     */
    _controller.resetFilter = () => {
        _controller.filter.cateogry = 0;
        _controller.sendNewFilter(true);
    };

    _controller.getAll = () => {
        if(!_categoriesPromise) {
            _categoriesPromise = DataService.getAllCategories().then((categories) => {
                Array.prototype.push.apply(_categories, categories);
            });
        }

        return _categories;
    };

    /**
     * Notify the channel about the filter
     * This function should not be called outside `_controller.sendNewFilter`
     * @private
     */
    function _sendFilter() {
        var filter = angular.copy(_controller.filter);

        if(!filter.category) filter.category = null;

        CTHashService.set('kategorie', _controller.filter.category);
        _channel.notify(AppConstants.CHANNEL_FILTER.ACTION_FILTER, filter);
    }


    /**
     * When the hash changes we check if the filter needs to be updated
     * @private
     */
    function _applyFilterFromHash() {
        var filter = parseInt(CTHashService.get('kategorie') || 0);

        if(filter !== parseInt(_controller.filter.category)) {
            _controller.filter.category = filter;
            _controller.sendNewFilter(true);
        }
    }


    (function init() {
        CTHash.change(_applyFilterFromHash);
    }());
}];
