/**
 * @file share-form.js
 * @description
 * Load into your application like `myApp.controller('share-form.js', require('share-form.js.js'))`
 */
const moduleName = 'cambuildr.directive.share-form';

angular.module(moduleName, [
    require('../../services/ChannelRepository.js')
]).directive('shareForm', [function() {
    return {
        templateUrl : 'theme/share-form',
        // replace     : true,
        controllerAs: 'share',
        controller  : ["$scope", "$attrs", "$parse", "AppConstants", "ChannelRepository", function($scope, $attrs, $parse, AppConstants, ChannelRepository) {
            var _controller       = this,
                _itemController   = () => $parse($attrs.itemController)($scope),
                _item             = () => $parse($attrs.item)($scope),
                _channel = ChannelRepository.get(AppConstants.CHANNEL_SHARE.NAME);


            /**
             * Get the Facebook AppID
             */
            _controller.getFacebookAppId = () => AppConstants.FacebookAppId;

            /**
             * Get the share URL
             */
            _controller.getShareURL = () => _itemController().getShareURL();

            /**
             * Get the share url as encoded string
             */
            _controller.getShareURLEncoded = () => encodeURIComponent(_controller.getShareURL());


            /**
             * Get the Twitter-Share `Text`
             */
            _controller.getTwitterText = () => AppConstants.SHARE.TWITTER.TEXT;

            /**
             * Get the Twitter-Share `Hashtags`
             */
            _controller.getTwitterHashtags = () => AppConstants.SHARE.TWITTER.HASHTAGS;

            /**
             * Get the Twitter-Share `Related`
             */
            _controller.getTwitterRelated = () => AppConstants.SHARE.TWITTER.RELATED;

            /**
             * Get the current item id
             */
            _controller.getItemID = () => (_item() || {}).id;

            /**
             * Close this form
             */
            _controller.close = () => $parse($attrs.onclose)($scope);

            /**
             * Send channel notification
             * @param provider
             */
            _controller.track = (provider) => {
                _channel.notify(AppConstants.CHANNEL_SHARE.ACTION_SHARE, provider);
            };


            (function init() {

            }());
        }]
    };
}]);

module.exports = moduleName;
