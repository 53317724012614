/**
 * Require Infinite Scroll
 */
require('ng-infinite-scroll/build/ng-infinite-scroll.js');
angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);

/**
 * Require imagesLoaded
 */
window.imagesLoaded = require('imagesloaded');
/**
 * Require masonry
 */
window.Masonry = require('masonry-layout');

require('ng-debounce/dist/ng-debounce');

require('../../../global/src/js/helper');

angular.module('cambuildr.module.ugc', [
           'ngSanitize',
           'ngAnimate',

           'debounce',

           require('../../../global/src/js/velocity.ui.angular.js'),
           require('../../../global/src/js/directive/selecty.js'),
           require('../../../global/src/js/modules/directive/share-form.js'),
           require('../../../global/src/js/directive/moderationStamp.js'),
           require('../../../global/src/js/filter/links.js'),

           require('../../../global/src/js/services/ChannelRepository.js'),
           require('../../../global/src/js/services/EventTracker.js'),
           require('../../../global/src/js/modules/directive/state-class'),


           'ct.services.api',

           'ct.constant',
           'ct.directives.mediaDrop',
           'ct.directives.stateButton',
           'ct.directives.labelHide',
           'multiVideoDirective',

           'ct.services.hash',
           'ct.directives.masonry',

           'infinite-scroll'
       ])

       .constant('POPUP_HASH_KEY', 'entry')
       .constant('AppConstants', angular.extend(require('../../../global/src/js/constants/module.json'), {}))

       .value('AppLanguage', false)

       .animation('.jsAnimationSlideIn', require('../../../global/src/js/animations/_slideIn.js'))

       .service('DataService', require('./service/StoryDataService.js'))

       .controller('ActionbarController', require('../../../global/src/js/modules/controller/ActionbarController.js'))
       .controller('SearchController', require('../../../global/src/js/modules/controller/SearchController.js'))
       .controller('CategoryController', require('../../../global/src/js/modules/controller/CategoryController.js'))
       .controller('SignupController', require('../../../global/src/js/modules/controller/SignupController.js'))
       .controller('SocialMediaSignupController', require('../../../global/src/js/modules/controller/SocialMediaSignupController.js'))
       .controller('UseFacebookUserImageController', require('../../../global/src/js/modules/controller/UseFacebookUserImageController.js'))
       .controller('TagListingController', require('../../../global/src/js/modules/controller/TagListingController.js'))
       .controller('WallController', require('../../../global/src/js/modules/controller/WallController.js'))
       .controller('PopupController', require('../../../global/src/js/modules/controller/PopupController.js'))
       .controller('StoryController', require('./controller/StoryController.js'))
       .controller('VoteController', require('./controller/VoteController.js'))
       .controller('CommentSignupController', require('./controller/CommentSignupController.js'))
       .controller('CommentsController', require('./controller/CommentsController.js'))
       .controller('CommentController', require('./controller/CommentController.js'))
       .controller('ActionsController', require('../../../global/src/js/modules/controller/ActionsController.js'))
       .controller('CategorySelectionController', require('./controller/CategorySelectionController.js'))
       .controller('VideoSelectionController', require('./controller/VideoSelectionController.js'))
       .controller('StorySignupPreviewController', require('./controller/StorySignupPreviewController.js'))
       .controller('WallStampController', require('../../../global/src/js/modules/controller/WallStampController.js'))


       .directive('shareWindow', require('../../../global/src/js/modules/directive/share-window.js'))

       .run(require('./run/DetailLinkCompatibility.js'))
       .run(require('../../../global/src/js/modules/run/PopupSupervisor.js'))
       .run(require('../../../global/src/js/modules/run/MasonryReset.js'));

