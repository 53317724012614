/**
 * @ngdoc service
 * @name CTEventTracker
 *
 * @description
 * Event-Tracker for Google Analytics and Facebook
 */
const moduleName = 'cambuildr.services.events';

angular.module(moduleName, []).service('EventTracker', ["$log", function($log) {

    const EventTracker = this;

    /**
     * @description
     * sends an event to google analytics and facebook, if these functions are available (= if the pixel code is set in the admin mask)
     *
     * @param {array} trackingParams an array of tracking params.
     *      needed in the following order: category, action, opt_label, opt_value, opt_noninteraction
     *      (https://developers.google.com/analytics/devguides/collection/gajs/eventTrackerGuide#SettingUpEventTracking)
     */
    EventTracker.track = function(trackingParams) {
        // google analytics tracking allows sending multiple params, as described in the function header.
        if(typeof ga === 'function') {
            ga.apply(this, ['send', 'event'].concat(trackingParams));
        }

        // for facebook tracking, the 2nd param is used as event name
        if(typeof fbq === 'function') {
            fbq('trackCustom', trackingParams[1]);
        }

    };
}]);

module.exports = moduleName;
