Array.shuffle = (srcArray) => {
    var counter = srcArray.length,
        _array = srcArray.splice(0);

    // While there are elements in the srcArray
    while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = _array[counter];
        _array[counter] = _array[index];
        _array[index] = temp;
    }

    return _array;
};
