/**
 * @file SignupController
 * @description
 * Load into your application like `myApp.controller('SignupController', require('SignupController.js'))`
 */
module.exports = ["$scope", "CTHashService", "DataService", "ChannelRepository", "AppConstants", "EventTracker", function($scope, CTHashService, DataService, ChannelRepository, AppConstants, EventTracker) {
    var _controller      = this,
        _networkActivity = false,
        _channel         = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME),

        _preRatingValue = 0;


    /**
     * Set network activity when flag is defined
     * Get when flag is not defined
     *
     * @param {boolean} [flag]
     */
    _controller.hasNetworkActivity = (flag) => {
        if(flag === true || flag === false) {
            return (_networkActivity = flag);
        }
        else {
            return _networkActivity;
        }
    };


    /**
     * Reset all data
     */
    _controller.reset = () => {
        _controller.data = {
            person: {},
            rating: 0
        };
        _controller.setDisclaimerAccepted(false);


        _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_COMMENT_RESET);
        $scope.action.close();
    };

    /**
     * Initialize disclaimer_accepted only when config Value is set
     * @param val
     */

    _controller.setDisclaimerAccepted = function(val) {
        // AppConstants.ACCEPT_DISCLAIMER_ENABLED set in index.blade.php
        if (AppConstants.ACCEPT_DISCLAIMER_ENABLED) {
            _controller.data.disclaimer_accepted = val;
        }
    };

    /**
     * Submit the comment
     */
    _controller.submit = () => {
        _controller.hasNetworkActivity(true);
        DataService.submitComment($scope.item.id, _controller.data)
                   .then((submission) => {
                       _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_COMMENT_SUBMITTED, submission);

                       EventTracker.track(['Cambuildr', 'Hat auf UGC-Seite kommentiert']);
                   })
                   .then(_controller.reset)
                   .finally(() => _controller.hasNetworkActivity(false));
    };


    /**
     * React on uploading an image
     *
     * @param image_id
     * @param image
     */
    _controller.mediaOnUpload = function(image_id, image) {

        _controller.data.image_id  = image_id;
        _controller.data.image = image;
    };

    /**
     * React on removing an images
     */
    _controller.mediaOnRemove = function() {

        delete _controller.data.image;
        delete _controller.data.image_id;
    };


    /**
     * get classes for a rating star
     * @param rate
     */
    _controller.getRatingClasses = (rate) => ({
            'fa-star': _preRatingValue > 0 ? _preRatingValue >= rate : _controller.data.rating >= rate,
            'fa-star-o': _preRatingValue > 0 ? _preRatingValue < rate : _controller.data.rating < rate,
            'ct-rating__icon--pre': _preRatingValue >= rate
    });

    /**
     * set the given rating
     * @param rate
     */
    _controller.setRating = (rate) => {
        if(rate === _controller.data.rating) {
            _controller.data.rating = 0;
        } else {
            _controller.data.rating = rate;
        }
    };

    /**
     * set preshow rating
     * @param rate
     */
    _controller.mouseOverRate = (rate) => (_preRatingValue = rate);

    /**
     * reset preshow rating
     * @param rate
     */
    _controller.mouseOutRate = (rate) => (_preRatingValue = 0);

    (function init() {

        /**
         * Initialize the signup data
         * @type {{person: {}}}
         */
        _controller.reset();

    }());
}];
