module.exports = ["CTServiceAPI", "AppConstants", "AppLanguage", "$q", "ChannelRepository", function(CTServiceAPI, AppConstants, AppLanguage, $q, ChannelRepository) {
    const _service = this;

    let _categories   = [],
        _storiesCache = {},
        _pageCache    = {},
        _items        = [];

    const StoryModel       = function(data) {
              this.__setData(data);
          },
          CommentModel     = function(data) {
              this.__setData(data);
          },
        _channel = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME);
    StoryModel.prototype   = {
        update(data) {
            return CTServiceAPI.put(`/pages/${AppConstants.PAGE_ID}/user_generated_contents/${this.id}`, data)
                               .then((data) => this.__setData(data.user_generated_content));
        },

        getComments() {
            return _service.getComments(this.id).then((comments) => {
                this.comments = comments;
            });
        },

        blockPerson() {
            return CTServiceAPI.post(`/people`, {id: this.person.id, blocked: true});
        },

        __setData(data) {
            angular.extend(this, data);
        }
    };
    CommentModel.prototype = {
        update(data) {
            return CTServiceAPI.put(`/pages/${AppConstants.PAGE_ID}/user_generated_contents/${this.user_generated_content_id}/comments/${this.id}`, data)
                               .then((data) => this.__setData(data.user_generated_content_comment));
        },

        getTruncatedText() {
            var text   = (this.text || ''),
                teaser = (/.{0,120}\w+/.exec(text) || [''])[0];

            return teaser + (text == teaser ? '' : '…');
        },

        blockPerson() {
            return CTServiceAPI.post(`/people`, {id: this.person_id, blocked: true});
        },

        __setData(data) {
            angular.extend(this, data);
        }
    };


    /**
     * Submit given data to the commitment module
     *
     * @param data
     */
    _service.submit = (data) => {

        //Define Story defaults
        let _defaults = {
            header    : '',
            media_type: 'NONE',
            categories: []
        };

        //If an language is available set it into the story
        if(AppLanguage !== false) {
            _defaults.lang = AppLanguage.code;
            data.person.lang = AppLanguage.code;
        }

        //Send the Story
        let promise = CTServiceAPI
            .post(`/pages/${AppConstants.PAGE_ID}/user_generated_contents`, angular.extend(_defaults, data))
            .then((submission) => submission.user_generated_content);

        //Notify the submission channel
        promise.then((submission) => {
            _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_SUBMITTED, new StoryModel(submission), 'item', 'story');
        });


        return promise;
    };
    /**
     * Save a vote
     *
     * @param id
     * @param data
     */
    _service.submitVote = (id, data) => CTServiceAPI.post(`/pages/${AppConstants.PAGE_ID}/user_generated_contents/${id}/vote`, data);


    /**
     * Save a vote
     *
     * @param storyId
     * @param commentId
     * @param data
     */
    _service.submitVoteComment = (storyId, commentId, data) => CTServiceAPI.post(`/pages/${AppConstants.PAGE_ID}/user_generated_contents/${storyId}/comments/${commentId}/votes`, data);

    /**
     * Submit a comment
     * @param id
     * @param data
     */
    _service.submitComment = (id, data) => CTServiceAPI.post(`/pages/${AppConstants.PAGE_ID}/user_generated_contents/${id}/comments`, data)
                                                       .then((response) => angular.extend(response.user_generated_content_comment, data));

    /**
     * Load comments
     * @param id
     */
    _service.getComments = (id) => CTServiceAPI.get(`/pages/${AppConstants.PAGE_ID}/user_generated_contents/${id}/comments`)
                                               .then((response) => response.user_generated_content_comments.map((item) => new CommentModel(item)));

    /**
     * Check if the given data is valid
     *
     * @param data
     * @return {Array|boolean}
     */
    _service.isInvalid = (data) => {
        var missingRequiredKeys = [];

        if(!data.person) {
            missingRequiredKeys.push('person.name');
            missingRequiredKeys.push('person.surname');
            missingRequiredKeys.push('person.email');
        }
        else {
            if(!data.person.name) {
                missingRequiredKeys.push('person.name');
            }
            if(!data.person.surname) {
                missingRequiredKeys.push('person.surname');
            }
            if(!data.person.email) {
                missingRequiredKeys.push('person.email');
            }
            if(!data.text) {
                missingRequiredKeys.push('text');
            }
        }

        return missingRequiredKeys.length ? missingRequiredKeys : false;
    };

    /**
     * Load the given page with the given page size
     *
     * @param page_nr
     * @param page_size
     *
     * @return {Promise.<T>}
     */
    _service.loadPage = (page_nr = 0, params = {}, page_size = 20) => {
        const defaults = {
                  use_cache: AppConstants.USE_CACHE
                  // state    : 1
              },
              filter   = angular.extend(defaults, params, {page_nr, page_size}),
              cachekey = JSON.stringify(filter);



        //If an language is available set it into the story
        if(AppLanguage !== false) {
            filter.lang = AppLanguage.code
        }

        if(!_pageCache[cachekey]) {
            _pageCache[cachekey] = CTServiceAPI.get(`/pages/${AppConstants.PAGE_ID}/user_generated_contents`, filter)
                               .then((response) => {
                                   var items = response.user_generated_contents.map((item) => new StoryModel(item));

                                   if(AppConstants.RANDOMIZE) items = Array.shuffle(items);

                                   _items = _items.concat(items);

                                   delete _pageCache[cachekey];

                                   return $q.all(items.map((item) => item.getComments())).then(() => {
                                       return {
                                           items,
                                           total_count: response.total_count
                                       };
                                   });
                               });
        }

        return _pageCache[cachekey];
    };

    _service.getNextID = (forId) => $q((resolve, reject) => {
        var total_count = Infinity,
            _items = [];
        function checkForId() {
            if(total_count <= _items.length) return reject();

            _service.loadPage(Math.floor(_items.length / 20))
                .then((data) => {
                    _items = _items.concat(data.items);
                    total_count = data.total_count;
                }, reject)
                .then(() => {
                    var index;
                    for(index=0; index < _items.length; index++) {
                        if(_items[index].id == forId) {
                            if(!_items[index+1]) return checkForId();

                            return resolve(_items[index+1].id);
                        }
                    }
                    checkForId();
                });
        }

        checkForId();
    });
    _service.getPreviousID = (forId) => $q((resolve, reject) => {
        var total_count = Infinity,
            _items = [];
        function checkForId() {
            if(total_count <= _items.length) return reject();

            _service.loadPage(Math.floor(_items.length / 20))
                .then((data) => {
                    _items = _items.concat(data.items);
                    total_count = data.total_count;
                }, reject)
                .then(() => {
                    var index;
                    for(index=_items.length-1; index >= 0; index--) {
                        if(_items[index].id == forId) {
                            if(!_items[index-1]) return checkForId();

                            return resolve(_items[index-1].id);
                        }
                    }
                    checkForId();
                });
        }

        checkForId();
    });


    /**
     * Return a single item
     *
     * @param id
     */
    _service.get = (id) => {
        if(!_storiesCache[id]) {
            _storiesCache[id] = CTServiceAPI.get(`/pages/${AppConstants.PAGE_ID}/user_generated_contents/${id}`)
                                            .then((response) => new StoryModel(response.user_generated_content));
        }
        return _storiesCache[id];
    };


    _service.getAllCategories = ((defer) => () => {
        let filter = {};


        //If an language is available set it into the story
        if(AppLanguage !== false) {
            filter.lang = AppLanguage.code
        }

        if(!defer) {
            defer = CTServiceAPI.get(`/pages/${AppConstants.PAGE_ID}/categories`, filter)
                                .then((response) => response.categories);
        }

        return defer;
    })(false);

    return _service;
}];
