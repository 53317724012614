/**
 * @file share-window
 * @description
 * Load into your application like `myApp.controller('share-window', require('share-window.js'))`
 */
module.exports = [() => ({
    /** @property {string} limit directive to class definition */
    restrict: 'A',

    link: function($scope, $element, $attrs) {

        function shareWindowDirectiveClickHandler($event) {
            $event.preventDefault();

            var windowOptions = [];

            windowOptions.push('width=500');
            windowOptions.push('height=375');

            windowOptions.push('top=' + 0);
            windowOptions.push('left=' + 0);

            windowOptions.push('scrollbars=yes');

            var shareWindow = window.open($attrs.href, 'Window', windowOptions.join(','));
            shareWindow.focus();
        }

        $element[0].addEventListener('click', shareWindowDirectiveClickHandler);

        $scope.$on('$destroy', function() {
            $element[0].removeEventListener('click', shareWindowDirectiveClickHandler);
        });
    }
})];
