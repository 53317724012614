/**
 * @file SignupController
 * @description
 * Load into your application like `myApp.controller('SignupController', require('SignupController.js'))`
 */
module.exports = ["$scope", "DataService", "ChannelRepository", "AppConstants", function($scope, DataService, ChannelRepository, AppConstants) {
    var _controller      = this,
        _networkActivity = false,
        _channel         = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME),
        _comments        = [],
        _comment         = {},
        _commentsPromise = false,
        _item            = () => ($scope.item || {});


    /**
     * Set network activity when flag is defined
     * Get when flag is not defined
     *
     * @param {boolean} [flag]
     */
    _controller.hasNetworkActivity = (flag) => {
        if(flag === true || flag === false) {
            return (_networkActivity = flag);
        }
        else {
            return _networkActivity;
        }
    };


    _controller.all = () => {
        if(!_commentsPromise && _item().id) {
            _controller.hasNetworkActivity(true);
            _commentsPromise = DataService.getComments(_item().id)
                                          .then((comments) => Array.prototype.push.apply(_comments, comments))
                                          .finally(() => _controller.hasNetworkActivity(false));
        }

        return _comments;
    };

    _controller.canVoteComment = () => !!AppConstants.COMMENT_VOTING;

    function closePopup() {
        _comments = [];
        _commentsPromise = false;
    }

    (function init() {
        ChannelRepository.get(AppConstants.CHANNEL_POPUP.NAME)
                         .subscribe(AppConstants.CHANNEL_POPUP.ACTION_CLOSE, closePopup);

        $scope.$on('$destroy', () => {
            ChannelRepository.get(AppConstants.CHANNEL_POPUP.NAME)
                             .unsubscribe(AppConstants.CHANNEL_POPUP.ACTION_CLOSE, closePopup);
        });
    }());
}];
