/**
 * @ngdoc directive
 * @name ct.directives.directive:selecty
 * @restrict A
 *
 * @author Michael Scharl <ms@campaigning-bureau.com>
 *
 * @description
 * Wrap markup to make a styleable SelectField
 *
 * @example
 <doc:example module="ct.directives.selecty">
 <doc:source>
 <script>
 angular.module('ct.directives.selecty').controller('testCtrl', ["$scope", function($scope) {
        $scope.filter = {};
        $scope.filter.categories = {"eins":{id:1,name:"Test 1"}, "zwei":{id:2,name:"Test 2"}, "drei":{id:3,name:"Test 3"}};
        $scope.filter.category = 3;
    }]);
 </script>
 <div ng-controller="testCtrl">
 <h2>Selected: {{filter.category}}</h2>
 <div data-selecty="filter.category" data-options="value.id as value.name for (key, value) in filter.categories" class="form-control"></div>
 </div>
 </doc:source>
 </doc:example>
 */
const moduleName        = 'cambuildr.directive.selecty';
const NG_OPTIONS_REGEXP = /^\s*([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+group\s+by\s+([\s\S]+?))?\s+for\s+(?:([\$\w][\$\w]*)|(?:\(\s*([\$\w][\$\w]*)\s*,\s*([\$\w][\$\w]*)\s*\)))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/;

angular.module(moduleName, []).directive('selecty', [() => ({
    // Restrict the Directive to attributes
    restrict: 'A',

    //Tell angular we want our own markup
    transclude: 'element',

    //We dont want our markup to be child of the Directive
    //instead we want our markup the to BE the directive
    replace: true,

    //create a new child scope
    scope: true,

    //Our own markup
    template: function(element, attrs) {
        var additionalAttributes = [];
        angular.forEach(attrs, function(value, key) {
            var match;
            if(!(match = key.match(/^selecty(.+)$/))) {
                return;
            }
            var attributeName = match[1].toLowerCase();
            if(attributeName == 'options' || attributeName == 'model') {
                return;
            }

            additionalAttributes.push('ng-' + attributeName + '="' + value + '"');
        });

        var selecty = '<div class="ct-selecty label-hide">';
        selecty += '<span class="ct-selecty__selected control-label">{{ selectedOption() || "' + (attrs.placeholder || 'Auswählen …') + '" }}</span>';
        selecty += '<span class="ct-selecty__trigger"></span>';
        selecty += '<div class="ct-selecty__select"><select ng-model="' + attrs.selecty + '" ng-options="' + attrs.options + '" ' + additionalAttributes.join(' ') + '></select></div>';
        selecty += '</div>';

        return selecty;
    },

    //Create the controller
    controller: ["$scope", "$element", "$attrs", "$parse", function _selectyControllerFunction($scope, $element, $attrs, $parse) {


        function parseOptions() {
            var match, parsed = {};
            if(!(match = $attrs.options.match(NG_OPTIONS_REGEXP))) {
                return "";
            }

            parsed.model     = $parse($attrs.selecty)($scope);
            parsed.displayFn = $parse(match[2] || match[1]);
            parsed.optionsFn = $parse(match[7]);
            parsed.options   = parsed.optionsFn($scope);
            parsed.valueName = match[4] || match[6];
            parsed.valueFn   = $parse(match[2] ? match[1] : parsed.valueName);
            parsed.label     = "";
            parsed.locals    = {};

            return parsed;
        }


        /**
         * Returns the selected value of the selectbox
         *
         * @returns {*}
         */
        $scope.selectedOption = function() {
            const match = parseOptions();

            var model     = match.model,
                displayFn = match.displayFn,
                optionsFn = match.optionsFn,
                options   = match.options,
                valueName = match.valueName,
                valueFn   = match.valueFn,
                label     = match.label,
                locals    = match.locals;

            angular.forEach(options, function(option, key) {
                locals[valueName] = option;
                var value         = valueFn($scope, locals);

                if(angular.equals(value, model)) {
                    label = displayFn($scope, locals);
                }
            });
            return label;
        };
    }]
})]);

module.exports = moduleName;
