module.exports = ["$scope", "ChannelRepository", "AppConstants", function($scope, ChannelRepository, AppConstants) {
    const _channel = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME);

    $scope.isVisible = false;

    _channel.subscribe(AppConstants.CHANNEL_SUBMISSION.ACTION_SUBMITTED, function(submission, scopeProperty, context) {
        $scope[scopeProperty] = submission;
        setTimeout(() => $scope.$applyAsync(() => ($scope.isVisible = true)), 100);
    });
}];
