/**
 * @file UseFacebookUserImageController
 * @description
 * Load into your application like `myApp.controller('UseFacebookUserImageController', require('UseFacebookUserImageController.js'))`
 */
module.exports = ["$scope", "$q", "ChannelRepository", "AppConstants", function($scope, $q, ChannelRepository, AppConstants) {
    var _controller = this,
        _channel = ChannelRepository.get(AppConstants.CHANNEL_SOCIALMEDIA.NAME);


    //Fetch user image after facebook connect
    function didConnectWithFacebook(fb_data) {

        $scope.signup.hasNetworkActivity(true);

        //Create a promise while communication with FB
        $q((resolve, reject) => {
            console.log('in connect with facebook')


            //Set the API Call
            FB.api('/me/picture', {width: 1920, fields: 'is_silhouette,url'}, (response) => {
                if(response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            });
        })
            .then((data) => {

                //If image is no silhouette assign it to the data model
                if(!data.is_silhouette) {
                    $scope.signup.mediaOnUpload(-1, data.url);

                    if($scope.signup.mediaDrop) {
                        $scope.signup.mediaDrop.setMedia(data.url, 'IMG');
                    }
                }
            })
            .finally(() => $scope.signup.hasNetworkActivity(false));
    }

    (function init() {
        //Subscribe to the channel
        _channel.subscribe(AppConstants.CHANNEL_SOCIALMEDIA.ACTION_FB_CONNECT, didConnectWithFacebook);

        $scope.$on('$destroy', () => {
            _channel.unsubscribe(AppConstants.CHANNEL_SOCIALMEDIA.ACTION_FB_CONNECT, didConnectWithFacebook);
        });
    }());
}];
