module.exports = ["AppConstants", function(AppConstants) {
    return {
        beforeAddClass: function(element, className, done) {
            if(className == 'ng-hide') {

                // set initial Styles before Animation
                element[0].style.willChange = 'height';

                element.css({
                    'overflow': 'hidden'
                });

                // slide Out Element
                jQuery(element).velocity({
                    height: 0
                }, AppConstants.ANIMATION.EASINGS.DEFAULT, done);


                return function() {
                    //remove the style so that the CSS inheritance kicks in
                    element[0].style.height = '';
                    element[0].style.overflow = '';
                    element[0].style.willChange = '';
                    element[0].style.transform = '';

                };
            } else {
                done();
            }
        }, removeClass: function(element, className, done) {

            if(className == 'ng-hide') {

                var height = element.height();

                // set initial Styles before Animation

                element.css({
                    'overflow': 'hidden',
                    'height': 0
                });

                element[0].style.willChange = 'height';


                // slide the Element In
                jQuery(element).velocity({
                    height: height
                }, AppConstants.ANIMATION.EASINGS.DEFAULT, done);

                return function() {
                    //remove the style so that the CSS inheritance kicks in
                    element[0].style.height = '';
                    element[0].style.overflow = '';
                    element[0].style.willChange = '';
                    element[0].style.transform = '';

                };

            } else {
                done();
            }
        }
    };
}];


