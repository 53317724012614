var moduleName = 'parseLinkFilter';

angular.module(moduleName, []).filter('parseLinks', ["$sce", function($sce) {

    return function(input) {

        var regEx = new RegExp('(((http|https|ftp)://)|mailto:|www\.)([^<\\s]+)', 'ig');

        function generateLinkFromMatch(match, protocol, p2, p3, url) {
            var stringSuffix = '', displayURL;

            if(url.substr(-1) === '.' || url.substr(-1) === '?' || url.substr(-1) === '!' || url.substr(-1) === ',') {
                stringSuffix = url.substr(-1);
                url          = url.substr(0, url.length - 1);
            }

            //handle www.xy.at domains
            if(protocol === "www.") {
                protocol = "http://";
                url      = "www." + url;
            }

            displayURL = url;
            if(displayURL.substr(-1) === '/') {
                displayURL = displayURL.substr(0, displayURL.length - 1);
            }

            return '<a href="' + protocol + url + '" target="_blank" >' + displayURL + '</a>' + stringSuffix;
        }


        return $sce.trustAsHtml(input.replace(regEx, generateLinkFromMatch));
    };
}]);

module.exports = moduleName;
