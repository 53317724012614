/**
 * @file WallController
 * @description
 * Load into your application like `myApp.controller('WallController', require('WallController.js'))`
 */
module.exports = ["$scope", "DataService", "AppConstants", "ChannelRepository", "$q", function($scope, DataService, AppConstants, ChannelRepository, $q) {
    var _controller          = this,

        /**
         * Get the search channel
         * @type {CTChannel}
         * @private
         */
        _channelFilter       = ChannelRepository.get(AppConstants.CHANNEL_FILTER.NAME),

        /**
         * Get the wall channel
         * @type {CTChannel}
         * @private
         */
        _channelWall       = ChannelRepository.get(AppConstants.CHANNEL_WALL.NAME),

        /**
         * All loaded items
         *
         * @type {Array}
         * @private
         */
        _items               = [],

        /**
         * Number off total available items
         *
         * @type {number}
         * @private
         */
        _totalItems          = 0,

        /**
         * flag if some activity is going on
         *
         * @type {boolean}
         * @private
         */
        _networkActivity     = false,

        /**
         * Currently active filter
         *
         * @type {{}}
         * @private
         */
        _activeFilter        = {},

        /**
         * Avoid resolving the active request
         */
        _cancelActiveRequest = () => false;


    /**
     * Return all loaded items
     *
     * @return {Array}
     */
    _controller.items = () => _items.slice(0);

    /**
     * Tell if all items are loaded
     *
     * @return {boolean}
     */
    _controller.reachedTheEnd = () => _items.length >= _totalItems;


    /**
     * Set network activity when flag is defined
     * Get when flag is not defined
     *
     * @param {boolean} [flag]
     */
    _controller.hasNetworkActivity = (flag) => {
        if(flag === true || flag === false) {
            return (_networkActivity = flag);
        }
        else {
            return _networkActivity;
        }
    };

    /**
     * Load the next page
     */
    _controller.loadNextPage = () => {
        _controller.hasNetworkActivity(true);

        const page_nr = Math.floor(_items.length / AppConstants.PAGE_SIZE);

        //Create a function out of the request to cancel its completion
        _cancelActiveRequest = (() => {
            var _defer = $q.defer();

            DataService.loadPage(page_nr, _activeFilter, AppConstants.PAGE_SIZE).then(_defer.resolve, _defer.reject);

            _defer.promise.then((data) => {
                      _items      = _items.concat(filterNewItems(data.items));
                      _totalItems = data.total_count;
                  })
                  .then(() => _channelWall.notify(AppConstants.CHANNEL_WALL.ACTION_PAGELOAD, page_nr))
                  .finally(() => _controller.hasNetworkActivity(false));

            return _defer.reject;
        })();
    };

    /**
     * Filter out existing items
     * @param items
     * @return {Array.<T>|*}
     */
    function filterNewItems(items) {
        return items.filter((_newItem) => _items.every((_existingItem) => _existingItem.id != _newItem.id));
    }

    function applyItemsFilter(filter) {
        _cancelActiveRequest();

        for(var key in filter) {
            if(filter.hasOwnProperty(key)) {
                if(filter[key] === null) {
                    delete _activeFilter[key];
                }
                else {
                    _activeFilter[key] = filter[key];
                }
            }
        }

        _items = [];
        _controller.loadNextPage(true);
    }

    (function init() {
        _controller.loadNextPage();

        _channelFilter.subscribe(AppConstants.CHANNEL_FILTER.ACTION_FILTER, applyItemsFilter);

        $scope.$on('$destroy', () => {
            _channelFilter.unsubscribe(AppConstants.CHANNEL_FILTER.ACTION_FILTER, applyItemsFilter);
        });
    }());
}];
