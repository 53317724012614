/**
 * @file state-class.js
 * @description
 * Load into your application like `angular.module('myApp', [require('state-class.js')])`
 */
const angular      = require('angular'),
      ngModuleName = 'cambuildr.directive.state-class',
      ngModule     = angular.module(ngModuleName, []);

const CLASS_BASE          = 'ct-state-class',
      CLASS_NEW           = `${CLASS_BASE}--new`,
      CLASS_ACTIVE        = `${CLASS_BASE}--active`,
      CLASS_BLOCKED        = `${CLASS_BASE}--blocked`,

      STATES_WITH_MESSAGE = [0, 2],

    STATE_MESSAGE_NEW = 'Dieser Beitrag wird noch freigeschalten!',
    STATE_MESSAGE_ACTIVE = 'Dieser Beitrag ist aktiv.',
    STATE_MESSAGE_BLOCKED = 'Dieser Beitrag ist gesperrt!';

ngModule
    .value('StateClassClasses', {
        BASE  : CLASS_BASE,
        NEW   : CLASS_NEW,
        ACTIVE: CLASS_ACTIVE,
        BLOCKED: CLASS_BLOCKED
    })
    .value('StateClassMessages', {
        NEW   : STATE_MESSAGE_NEW,
        ACTIVE: STATE_MESSAGE_ACTIVE,
        BLOCKED: STATE_MESSAGE_BLOCKED
    })
    .value('StateClassStatesWithMessage', STATES_WITH_MESSAGE)
    .directive('stateClass', ["StateClassClasses", "StateClassStatesWithMessage", "StateClassMessages", function(StateClassClasses, StateClassStatesWithMessage, StateClassMessages) {
        return {
            link: ($scope, $element, $attrs) => {
                let messageElement = angular.element(`<div class="${StateClassClasses.BASE}__message"></div>`),
                    removeWatch    = $scope.$watch($attrs.stateClass, (newValue) => {
                        $element
                            .removeClass(StateClassClasses.NEW)
                            .removeClass(StateClassClasses.ACTIVE)
                            .removeClass(StateClassClasses.BLOCKED)
                        ;

                        messageElement.html('');

                        if(newValue === 0) {
                            if(StateClassStatesWithMessage.indexOf(0) !== -1) {
                                messageElement.html($attrs.stateMessage0 || StateClassMessages.NEW);
                            }
                            $element.addClass(StateClassClasses.NEW);
                        }
                        else if(newValue === 2) {
                            if(StateClassStatesWithMessage.indexOf(2) !== -1) {
                                messageElement.html($attrs.stateMessage2 || StateClassMessages.BLOCKED);
                            }
                            $element.addClass(StateClassClasses.BLOCKED);
                        }
                        else if(newValue === 1) {
                            if(StateClassStatesWithMessage.indexOf(1) !== -1) {
                                messageElement.html($attrs.stateMessage1 || StateClassMessages.ACTIVE);
                            }
                            $element.addClass(StateClassClasses.ACTIVE);
                        }
                        else {
                            if(window.console && console.warn) {
                                console.warn('Invalid State! Expected on of [%o,%o,%o] but found %o', 0, 1, 2, newValue);
                            }
                        }
                    });


                (function init() {
                    $element.addClass(StateClassClasses.BASE);
                    $element.append(messageElement);

                    $scope.$on('$destroy', () => removeWatch());
                }());
            }
        };
    }]);

module.exports = ngModuleName;
