/**
 * @file CommitmentController
 * @description
 * Load into your application like `myApp.controller('CommitmentController', require('CommitmentController.js'))`
 */
module.exports = ["$scope", "$filter", "AppConstants", "CTServiceAPIConfig", "CTHashService", "POPUP_HASH_KEY", function($scope, $filter, AppConstants, CTServiceAPIConfig, CTHashService, POPUP_HASH_KEY) {
    var _controller = this,
        _item       = () => ($scope.item || {});


    /**
     * Check if an image is available
     * @return {boolean}
     */
    _controller.hasImage = () => !!_item().image && !!_item().image_id;

    /**
     * Return a trusted HTML String for angular
     * @return {*}
     */
    _controller.getHTMLSafeText = () => $filter('parseLinks')(_item().text || '');

    (function init() {

    }());
}];
