/**
 * @file TagListingController
 * @description
 * Load into your application like `myApp.controller('TagListingController', require('TagListingController.js'))`
 */
module.exports = ["$scope", "$q", "CTServiceAPI", "ChannelRepository", "AppConstants", function($scope, $q, CTServiceAPI, ChannelRepository, AppConstants) {
    var _controller = this,
        _channel = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME);

    /**
     * Flag if tags couldn't be loaded
     * @type {boolean}
     */
    _controller.failedToLoad = false;

    /**
     * Are Tags loading?
     * @type {boolean}
     */
    _controller.loading = false;

    /**
     * All the tags
     * @type {Array}
     */
    _controller.tags = [];


    /**
     * Assign selected tags to the model
     */
    _controller.assignTagsToModel = () => {
        $scope.signup.data.person = $scope.signup.data.person || {};
        $scope.signup.data.person.tags = _controller.tags.filter((tag) => !!tag.selected);
    };

    _controller.toggle = (tag) => {
        tag.selected = !tag.selected;
        _controller.assignTagsToModel();
    };


    /**
     * add a custom entered tag
     * @param $event
     */
    _controller.addCustomTag = ($event) => {
        if($event.keyCode != 13) return;
        $event.preventDefault();


        var tagWithSameName = _controller.tags.filter((tag) => (tag.screen_name.toLocaleLowerCase() == _controller.custom_tag_label.toLocaleLowerCase()));
        if(tagWithSameName.length) {
            tagWithSameName[0].selected = true;
        } else {
            _controller.tags.push({
                screen_name: _controller.custom_tag_label,
                selected: true
            });
            _controller.assignTagsToModel();
        }

        _controller.custom_tag_label = '';
    };

    /**
     * Load tags from the API
     * @return {Promise.<T>}
     */
    function loadTags() {
        return CTServiceAPI.get('/tags').then((data) => {
            _controller.tags = data.tags.map((tag) => ({
                id         : tag.id,
                screen_name: tag.screen_name,
                prefix     : tag.prefix,
                image      : tag.image
            }));
        }, (err) => {
            _controller.failedToLoad = true;
            return $q.reject(err);
        });
    }

    /**
     * Reset the selection
     */
    function reset() {
        _controller.custom_tag_label = '';
        _controller.tags.every((tag) => {
            delete tag.selected;
            return true;
        });
    }
    
    function selectTags() {
        $scope.signup.data.person.tags.forEach((tag) => {
            _controller.tags.forEach((_tag) => {
                if(_tag.id == tag.id) {
                    _tag.selected = true;
                }
            })
        });
    }

    (function init() {
        _controller.loading = true;
        loadTags().finally(() => (_controller.loading = false));

        _channel.subscribe(AppConstants.CHANNEL_SUBMISSION.ACTION_RESET, reset);
        _channel.subscribe(AppConstants.CHANNEL_SUBMISSION.ACTION_SUBMITTED, selectTags);

        $scope.$on('$destroy', () => {
            _channel.unsubscribe(AppConstants.CHANNEL_SUBMISSION.ACTION_RESET, reset);
        });
    }());
}];
