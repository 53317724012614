/**
 * @file PopupController
 * @description
 * Load into your application like `myApp.controller('PopupController', require('PopupController.js'))`
 */
module.exports = ["$scope", "DataService", "ChannelRepository", "AppConstants", "POPUP_HASH_KEY", "CTHashService", function($scope, DataService, ChannelRepository, AppConstants, POPUP_HASH_KEY, CTServiceHash) {
    var _controller        = this,
        _channel           = ChannelRepository.get(AppConstants.CHANNEL_POPUP.NAME),
        _body              = document.body,
        _popupIsVisible    = false,
        _prepareVisibility = false,
        _networkActivity   = false,

        _previousID        = 0,
        _loadingPreviousID = 0,
        _nextID            = 0,
        _loadingNextID     = 0;


    /**
     * Flag to show that wrapper before animating everything else
     */
    _controller.prepareVisibility = () => _prepareVisibility;


    /**
     * Return if the popup should be visible
     */
    _controller.isVisible = () => _popupIsVisible;


    /**
     * Set network activity when flag is defined
     * Get when flag is not defined
     *
     * @param {boolean} [flag]
     */
    _controller.hasNetworkActivity = (flag) => {
        if(flag === true || flag === false) {
            return (_networkActivity = flag);
        }
        else {
            return _networkActivity;
        }
    };

    /**
     * Close the popup
     */
    _controller.close = () => CTServiceHash.del(POPUP_HASH_KEY);


    /**
     * Check if a previous itemID is available
     */
    _controller.hasPreviousID = () => !!_previousID;

    /**
     * Return the hash for the previous itemID
     */
    _controller.getPreviousIDHash = () => $scope.getHash({[POPUP_HASH_KEY]: _previousID});

    /**
     * Check if currently loading the Previous itemID
     */
    _controller.loadingPreviousID = () => _loadingPreviousID;

    /**
     * Get the hash to close the popup
     */
    _controller.getCloseHash = () => $scope.getHash({[POPUP_HASH_KEY]: false});

    /**
     * Check if a next itemID is available
     */
    _controller.hasNextID = () => !!_nextID;

    /**
     * Return the hash for the next itemID
     */
    _controller.getNextIDHash = () => $scope.getHash({[POPUP_HASH_KEY]: _nextID});

    /**
     * Check if currently loading the Next itemID
     */
    _controller.loadingNextID = () => _loadingNextID;


    /**
     * Show the popup with the given itemId
     * @param itemId
     */
    function showPopup(itemId) {
        _body.style.overflow = 'hidden';
        _prepareVisibility   = true;

        setTimeout(() => $scope.$applyAsync(() => loadPopupContent(itemId)), 1);
    }


    /**
     * Close the popup
     */
    function closePopup() {
        setTimeout(() => $scope.$applyAsync(() => {
            $scope.item = {};

            _body.style.overflow = 'auto';

            _popupIsVisible    = false;
            _prepareVisibility = false;
        }), 1);
    }

    /**
     * Load the popups content
     *
     * @param itemId
     */
    function loadPopupContent(itemId) {
        _controller.hasNetworkActivity(false);

        $scope.item = {};

        DataService.get(itemId)
                   .then((itemData) => {
                       _popupIsVisible = true;
                       $scope.item     = itemData;
                   })
                   .finally(() => _controller.hasNetworkActivity(false));

        _loadingNextID = true;
        DataService.getNextID(itemId)
                   .then((id) => (_nextID = id))
                   .finally(() => (_loadingNextID = false));

        _loadingPreviousID = true;
        DataService.getPreviousID(itemId)
                   .then((id) => (_previousID = id))
                   .finally(() => (_loadingPreviousID = false));
    }


    (function init() {
        _channel.subscribe(AppConstants.CHANNEL_POPUP.ACTION_OPEN, showPopup);
        _channel.subscribe(AppConstants.CHANNEL_POPUP.ACTION_CLOSE, closePopup);

        $scope.$on('$destroy', () => {
            _channel.unsubscribe(AppConstants.CHANNEL_POPUP.ACTION_OPEN, showPopup);
            _channel.unsubscribe(AppConstants.CHANNEL_POPUP.ACTION_CLOSE, closePopup);
        });
    }());
}];
