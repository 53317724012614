/**
 * @file ActionbarController
 * @description
 * Load into your application like `myApp.controller('ActionbarController', require('ActionbarController.js'))`
 */
module.exports = ["$scope", "CTHash", "CTHashService", "ChannelRepository", "AppConstants", function($scope, CTHash, CTHashService, ChannelRepository, AppConstants) {
    var _controller = this,
        _channel    = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME);

    _controller.signupFormOpen = false;

    _controller.shareFormOpen = false;

    _controller.shareForm = {
        open  : () => (_controller.shareFormOpen = true),
        close : () => (_controller.shareFormOpen = false),
        toggle: () => (_controller.shareFormOpen = !_controller.shareFormOpen)
    };


    function hashChangeHandler() {
        let newValue = !!CTHashService.get('einsenden');

        if(newValue && (newValue !== _controller.signupFormOpen)) {
            _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_OPEN_SIGNUP);
        }
        else if(!newValue && (newValue !== _controller.signupFormOpen)) {
            _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_CLOSE_SIGNUP);
        }

        _controller.signupFormOpen = newValue;
    }

    (function init() {
        CTHash.change(hashChangeHandler);
    }());
}];
