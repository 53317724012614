const moduleName = 'cambuildr.directive.moderation-stamp';
const ngModule = angular.module(moduleName, []);

ngModule.value('ModerationStampBaseIcon', 'fa-pencil');

ngModule.directive('moderationStamp', [
    'CTServiceAPI', 'ModerationStampBaseIcon', (CTServiceAPI, ModerationStampBaseIcon) => {

        const getCurrentUser = ((user, promise, uid) => () => {
            var _uid = CTServiceAPI.authenticationData().USER_IDENTIFIER;
            if(_uid !== uid) {
                uid = _uid;
                promise = false;
                user = {};
            }

            if(!promise && uid) {
                promise = CTServiceAPI.get(`/users/${uid}`)
                    .then((response) => (user = response.user));
            }

            return user;
        })({}, false, false);

        return {
            // Restrict the Directive to attributes
            restrict: 'A',

            //create a new child scope
            scope: {
                moderationStamp: '=',
            },

            //Our own markup
            template: require('./moderationStamp.ng-tpl.html'),

            controllerAs: 'moderation',

            //Create the controller
            controller: [
                '$scope', '$attrs', 'CTServiceAPI', function($scope, $attrs, CTServiceAPI) {
                    var _controller = this,
                        _menuIsVisible = false,
                        _activeRequest = false;

                    /**
                     * Check if current user has content edit permission
                     *
                     * @return {boolean}
                     */
                    _controller.hasContentEditPermission = () => {
                        var user = getCurrentUser();
                        return (user && user.has_content_edit_permission === true);
                    };

                    /**
                     * Set show flag to `show`
                     */
                    _controller.showMenu = () => (_menuIsVisible = true);

                    /**
                     * Set show flag to `hidden`
                     */
                    _controller.hideMenu = () => (_menuIsVisible = false);

                    /**
                     * @return {string} the icon for the stamp toggle
                     */
                    _controller.getBaseIcon = () => ModerationStampBaseIcon;

                    /**
                     * should menu be displayed
                     * @return {boolean}
                     */
                    _controller.displayMenu = () => _menuIsVisible && !_controller.hasNetworkActivity();

                    /**
                     * Get/Set the current network activity
                     *
                     * @param {boolean} [state] if set it will update the network state
                     *
                     * @return {boolean} the current state
                     */
                    _controller.hasNetworkActivity = (state) => {
                        if(state === false || state === true) {
                            _activeRequest = state;
                        }

                        return _activeRequest;
                    };

                    /**
                     * Get Icon classes for moderation stamp toggle
                     */
                    _controller.getIconClasses = () => ({
                        'fa-circle-o-notch fa-spin': _controller.hasNetworkActivity(),
                        [ModerationStampBaseIcon]: !_controller.hasNetworkActivity(),
                    });

                    /**
                     * Model is given state
                     * @param state
                     * @return {boolean}
                     */
                    _controller.modelIsState = (state) => ($scope.moderationStamp && $scope.moderationStamp.state === state);

                    _controller.updateState = (newState) => {
                        _controller.hasNetworkActivity(true);
                        _controller.hideMenu();

                        $scope.moderationStamp
                            .update({ state: newState })
                            .finally(() => _controller.hasNetworkActivity(false));
                    };

                    _controller.blockPerson = () => {
                        _controller.hasNetworkActivity(true);
                        _controller.hideMenu();

                        $scope.moderationStamp
                            .blockPerson()
                            .finally(() => _controller.hasNetworkActivity(false));
                    };


                    (function init() {

                    })();
                },
            ],
        };
    },
]);

module.exports = moduleName;
