/**
 * @file ActionsController
 * @description
 * Load into your application like `myApp.controller('ActionsController', require('ActionsController.js'))`
 */
module.exports = ["$scope", function($scope) {
    var _controller = this;

    _controller.active = '';

    _controller.openShare = () => (_controller.active = 'share');

    _controller.openVoting = () => (_controller.active = 'vote');

    _controller.openComment = () => (_controller.active = 'comment');

    _controller.close = () => (_controller.active = '');

    (function init() {

    }());
}];
