/**
 * @file PopupSupervisor
 * @description
 * Load into your application like `myApp.run(require('PopupSupervisor.js'))`
 */
module.exports = ["ChannelRepository", "AppConstants", "$rootScope", function(ChannelRepository, AppConstants, $rootScope) {
    var _currentKey = false,
        _channel    = ChannelRepository.get(AppConstants.CHANNEL_FILTER.NAME);

    function resetMasonry() {
        $rootScope.$broadcast('masonry.reset.loadindex');
    }

    _channel.subscribe(AppConstants.CHANNEL_FILTER.ACTION_FILTER, resetMasonry);
}];
