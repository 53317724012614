/**
 * @file VideoSelectionController
 * @description
 * Load into your application like `myApp.controller('StorySignupPreviewController', require('StorySignupPreviewController.js'))`
 */
module.exports = ["$scope", "AppConstants", function($scope, AppConstants) {
    var _controller  = this;

    $scope.item = {};

    (function init() {
        $scope.$watch(() => JSON.stringify($scope.signup.data), () => {
            let data = $scope.signup.data;

            $scope.item = {
                person: {
                    name: data.person.name || AppConstants.STORY_PREVIEW.name,
                    surname: data.person.surname || AppConstants.STORY_PREVIEW.surname
                },
                header: data.header,
                text: data.text || AppConstants.STORY_PREVIEW.text,
                image: data.image,
                video_url: data.video_url
            }
        });
    }());
}];
