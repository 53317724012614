/**
 * @file SignupController
 * @description
 * Load into your application like `myApp.controller('SignupController', require('SignupController.js'))`
 */
module.exports = ["$scope", "CTHashService", "DataService", "ChannelRepository", "AppConstants", "EventTracker", function($scope, CTHashService, DataService, ChannelRepository, AppConstants, EventTracker) {
    var _controller      = this,
        _networkActivity = false,
        _channel         = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME);


    /**
     * Set network activity when flag is defined
     * Get when flag is not defined
     *
     * @param {boolean} [flag]
     */
    _controller.hasNetworkActivity = (flag) => {
        if(flag === true || flag === false) {
            return (_networkActivity = flag);
        }
        else {
            return _networkActivity;
        }
    };


    /**
     * Check if the signup form should be opened
     */
    _controller.isOpen = () => !!CTHashService.get('einsenden');


    /**
     * Reset all data
     */
    _controller.reset = () => {
        _controller.data = {
            show_public: true,
            person: {}
        };

        if(_controller.mediaDrop && _controller.mediaDrop.removeMedia) {
            _controller.mediaDrop.removeMedia();
        }

        _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_RESET);
    };

    /**
     * Submit the statement
     */
    _controller.submit = () => {
        var invalidKeys = DataService.isInvalid(_controller.data),
            _submit     = () => DataService.submit(_controller.data)
                                           .then((submission) => {
                                               //TODO: Show story if moderation in ON
                                               _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_SUBMITTED, submission);

                                               EventTracker.track(['Cambuildr', 'Auf UGC-Seite eingetragen']);
                                           })
                                           .then(_controller.reset)
                                           .then(() => { CTHashService.del('einsenden'); })
                                           .then(() => {}) // TODO: Reload if moderation is OFF
                                           .finally(() => _controller.hasNetworkActivity(false));

        //Submit if data is valid
        if(!invalidKeys) {
            _controller.hasNetworkActivity(true);

            //Reference to mediaDrop is set by the directive
            if(!_controller.data.video_url && _controller.mediaDrop && _controller.mediaDrop.submitMedia) {
                _controller.mediaDrop.submitMedia().then(_submit);
            }
            else {
                _submit();
            }
        }
    };


    /**
     * React on uploading an image
     *
     * @param image_id
     * @param image
     */
    _controller.mediaOnUpload = function(image_id, image) {
        _controller.data.image_id  = image_id;
        _controller.data.image_url = image;
    };

    /**
     * React on removing an images
     */
    _controller.mediaOnRemove = function() {
        delete _controller.data.image;
        delete _controller.data.video_url;
        delete _controller.data.image_id;
    };


    (function init() {
        /**
         * Initialize the signup data
         * @type {{person: {}}}
         */
        _controller.reset();

    }());
}];
