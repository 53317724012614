/**
 * @file SignupController
 * @description
 * Load into your application like `myApp.controller('SignupController', require('SignupController.js'))`
 */
module.exports = ["$scope", "CTHashService", "DataService", "ChannelRepository", "AppConstants", "EventTracker", function($scope, CTHashService, DataService, ChannelRepository, AppConstants, EventTracker) {
    var _controller      = this,
        _networkActivity = false,
        _channel         = ChannelRepository.get(AppConstants.CHANNEL_SUBMISSION.NAME);


    /**
     * Set network activity when flag is defined
     * Get when flag is not defined
     *
     * @param {boolean} [flag]
     */
    _controller.hasNetworkActivity = (flag) => {
        if(flag === true || flag === false) {
            return (_networkActivity = flag);
        }
        else {
            return _networkActivity;
        }
    };


    /**
     * Reset all data
     */
    _controller.reset = () => {
        _controller.data = {
            person: {}
        };

        _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_VOTE_RESET);
        $scope.action.close();
    };

    /**
     * Submit the statement
     */
    _controller.submit = (item, context) => {
        const submitFn = context == 'vote-comment' ? () => DataService.submitVoteComment(item.user_generated_content_id, item.id, {person: {email: _controller.data.person.email}}) : () => DataService.submitVote(item.id, {email: _controller.data.person.email});

        //Submit if data is valid
        if(_controller.data.person.email) {
            _controller.hasNetworkActivity(true);
            submitFn()
                .then((submission) => {
                    _channel.notify(AppConstants.CHANNEL_SUBMISSION.ACTION_VOTE_SUBMITTED, submission);

                    //Need this because of inconsistency of the API
                    item.vote_count = item.vote_count + 1;
                    item.votecount = item.votecount + 1;

                    EventTracker.track(['Cambuildr', 'Hat auf UGC-Seite gevotet']);
                })
                .then(_controller.reset)
                .finally(() => _controller.hasNetworkActivity(false));
        }
    };

    function checkFacebookLoginAction(data, context, id) {
        if(context == _controller.context && id == $scope.item.id) {
            _controller.submit($scope.item, _controller.context);
        }
    }


    (function init() {
        /**
         * Initialize the signup data
         * @type {{person: {}}}
         */
        _controller.reset();


        ChannelRepository.get(AppConstants.CHANNEL_SOCIALMEDIA.NAME)
                         .subscribe(AppConstants.CHANNEL_SOCIALMEDIA.ACTION_FB_CONNECT, checkFacebookLoginAction);

        $scope.$on('$destroy', () => {
            ChannelRepository.get(AppConstants.CHANNEL_SOCIALMEDIA.NAME)
                             .unsubscribe(AppConstants.CHANNEL_SOCIALMEDIA.ACTION_FB_CONNECT, checkFacebookLoginAction);
        });
    }());
}];
